import React, { useEffect, useState } from 'react';
import { decode } from '../components/helper';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ImprintPage = () => {
  const [details, setDetails] = useState({ mail: '', tel: '' });
  useEffect(() => {
    const mail = decode('ZV9kXy5fbV9oX2Vfcl8tX2lfZV9yX2VfZl9wX2Vfb190X0BfZV90X2FfZV9i');
    const tel = decode('N182XzdfN18gXzNfM18zXzdfIF8pXzBfKF8gXzlfNF8r');
    setDetails({ mail, tel });
  }, []);

  return (
    <Layout>
      <Seo title="Impressum" />
      <div className="container">
        <div className="row">
          <div className="col-12 pt-5">
            <h1>Impressum</h1>
            <h2>Angaben gemäß § 5 TMG:</h2>
            <p>
              Beate Rehm, Keramikermeisterin
              <br /> Töpferei Rehm (Kleinunternehmerin)
              <br />
              Lange Gasse 22
              <br /> 72589 Westerheim
            </p>
            <h2>Kontakt:</h2>
            <p>
              Telefon: {details.tel}
              <br />
              E-Mail: <a href={'mailto:' + details.mail}>{details.mail}</a>
            </p>
            <h2>Umsatzsteuer:</h2>
            <p>Nach § 19 UstG Kleinunternehmer ohne Mehrwertsteuerausweis und Vorsteuerabzugsberechtigung</p>
            <h2>Streitschlichtung</h2>
            <p>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <h2>Haftung für Inhalte</h2>
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
              Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
              eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
              konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <h2>Haftung für Links</h2>
            <p>
              Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
              Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
              Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar.
            </p>
            <p>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
              umgehend entfernen.
            </p>
            <h2>Urheberrecht</h2>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
              Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
            <p>
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
              beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
              von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
            <p>
              Quelle:{' '}
              <a href="https://www.erecht24.de" target="_blanc" rel="noopener">
                eRecht24
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImprintPage;
